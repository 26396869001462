import React, { useState, useEffect, useRef } from 'react'
import {
  Container,
  Grid,
  Link,
  Heading,
  Text,
  Spacer,
  Flex,
  Box,
} from '@chakra-ui/react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import ReactRouterLink from '../components/link'
import ImageFrag from '../components/image-frag'
import { FlatBorder } from '../components/funky-border'

const BlogList = ({
  data: { articles },
  pageContext: {
    title,
    description,
    currentPage,
    numPages,
    breadcrumb,
    handle,
  },
}) => {
  // For pagination control
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  let filteredArticles = []

  //Filtered article list
  const [activeArticles, setActiveArticles] = useState(articles.nodes)

  // Subset of filtered articles for infinite scroll
  const [listLength, setListLength] = useState(12)

  // State to trigger load more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(activeArticles.length > 6)

  //Set a ref for the loading div
  const loadRef = useRef()

  // Handle intersection with load more div
  const handleObserver = (entities) => {
    const target = entities[0]
    if (target.isIntersecting) {
      setLoadMore(true)
    }
  }

  //Initialize the intersection observer API

  useEffect(() => {
    var today = Date.now()
    articles.nodes.forEach((article) => {
      let dateFound = false
      article.tags.forEach((tag) => {
        if (tag.includes('date:')) {
          dateFound = true
          let dateStr = tag.split(':', 2)
          let date = Date.parse(dateStr[1])
          if (date >= today) {
            filteredArticles.push(article)
          }
        }
      })
      if (!dateFound) {
        filteredArticles.push(article)
      }
    })
    setActiveArticles(filteredArticles)
  }, []) //eslint-disable-line

  useEffect(() => {
    var options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    }
    const observer = new IntersectionObserver(handleObserver, options)
    if (loadRef.current) {
      observer.observe(loadRef.current)
    }
  }, [])

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      setListLength(listLength + 6)
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = listLength < activeArticles.length
    setHasMore(isMore)
  }, [activeArticles]) //eslint-disable-line

  return (
    <Layout breadcrumbs={breadcrumb}>
      <Seo title={title} description={description}>
        {!isFirst && <meta name="robots" content="noindex, follow" />}
      </Seo>
      <Container py={10}>
        <Heading as="h1" mb={2}>
          {`${title} ${!isFirst ? `(page ${currentPage})` : ''}`}
        </Heading>

        {/* <Filter
          productList={articles.nodes}
          setActiveProducts={setActiveArticles}
        /> */}
        <Grid
          templateColumns={[
            '1fr',
            'repeat(2, 1fr)',
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
          ]}
          columnGap={6}
          rowGap={[4, 4, 4]}
        >
          {activeArticles.length > 0 &&
            listLength > 0 &&
            activeArticles.slice(0, listLength).map(
              (p) =>
                p != null && (
                  <Box>
                    <Link
                      to={`/${handle == 'news' ? 'blog' : handle}/${p.handle}`}
                      key={p.id}
                      as={ReactRouterLink}
                    >
                      <ImageFrag imageData={p.image} />
                    </Link>
                    <FlatBorder tags={p.tags} thickness={1} />
                    <Heading
                      as="h3"
                      size="md"
                      transition="color 0.25s ease-in-out"
                      mt={'0.5rem'}
                    >
                      {p.title}
                    </Heading>
                    <Text mt={'0.4rem'}>{p.excerpt}</Text>
                    <Link
                      to={`/${handle == 'news' ? 'blog' : handle}/${p.handle}`}
                      key={p.id}
                      as={ReactRouterLink}
                    >
                      <Text fontWeight={'bold'} mt={'0.4rem'}>
                        Read More
                      </Text>
                    </Link>
                  </Box>
                )
            )}
        </Grid>
      </Container>
      <Container py={10} ref={loadRef}>
        {loadMore && hasMore ? (
          <Text>Loading...</Text>
        ) : (
          <Flex>
            {!isFirst && (
              <Link to={`../${prevPage}`} rel="prev" as={ReactRouterLink}>
                ← Previous Page
              </Link>
            )}
            <Spacer />
            {!isFirst && !isLast && (
              <Link to={`../${nextPage}`} rel="next" as={ReactRouterLink}>
                Next Page →
              </Link>
            )}
            {isFirst && !isLast && (
              <Link to={nextPage} rel="next" as={ReactRouterLink}>
                Next Page →
              </Link>
            )}
          </Flex>
        )}
      </Container>
    </Layout>
  )
}

export default BlogList

export const blogListQuery = graphql`
  query blogListQuery(
    $handle: String!
    $sortOrder: [SortOrderEnum]
    $skip: Int!
    $limit: Int!
  ) {
    articles: allShopifyArticle(
      filter: { blog: { handle: { eq: $handle } } }
      limit: $limit
      skip: $skip
      sort: { order: $sortOrder, fields: publishedAt }
    ) {
      nodes {
        handle
        id
        blog {
          handle
          title
        }
        title
        excerpt
        tags
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
  }
`
